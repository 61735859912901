import { applyFilters } from "@vibrant/image";
class Stage {
  constructor(pipeline) {
    this.pipeline = pipeline;
    this._map = {};
  }
  names() {
    return Object.keys(this._map);
  }
  has(name) {
    return !!this._map[name];
  }
  get(name) {
    return this._map[name];
  }
  register(name, stageFn) {
    this._map[name] = stageFn;
    return this.pipeline;
  }
}
class BasicPipeline {
  constructor() {
    this.filter = new Stage(this);
    this.quantizer = new Stage(this);
    this.generator = new Stage(this);
  }
  _buildProcessTasks({
    filters,
    quantizer,
    generators
  }) {
    if (generators.length === 1 && generators[0] === "*") {
      generators = this.generator.names();
    }
    return {
      filters: filters.map((f) => createTask(this.filter, f)),
      quantizer: createTask(this.quantizer, quantizer),
      generators: generators.map((g) => createTask(this.generator, g))
    };
    function createTask(stage, o) {
      let name;
      let options;
      if (typeof o === "string") {
        name = o;
      } else {
        name = o.name;
        options = o.options;
      }
      return {
        name,
        fn: stage.get(name),
        options
      };
    }
  }
  async process(imageData, opts) {
    const { filters, quantizer, generators } = this._buildProcessTasks(opts);
    const imageFilterData = await this._filterColors(filters, imageData);
    const colors = await this._generateColors(quantizer, imageFilterData);
    const palettes = await this._generatePalettes(generators, colors);
    return {
      colors,
      palettes
    };
  }
  _filterColors(filters, imageData) {
    return Promise.resolve(
      applyFilters(
        imageData,
        filters.map(({ fn }) => fn)
      )
    );
  }
  _generateColors(quantizer, imageData) {
    return Promise.resolve(quantizer.fn(imageData.data, quantizer.options));
  }
  async _generatePalettes(generators, colors) {
    const promiseArr = await Promise.all(
      generators.map(({ fn, options }) => Promise.resolve(fn(colors, options)))
    );
    return Promise.resolve(
      promiseArr.reduce(
        (promises, promiseVal, i) => {
          promises[generators[i].name] = promiseVal;
          return promises;
        },
        {}
      )
    );
  }
}
export {
  BasicPipeline,
  Stage
};

