import { Swatch, hslToRgb } from "@vibrant/color";
const DefaultOpts = {
  targetDarkLuma: 0.26,
  maxDarkLuma: 0.45,
  minLightLuma: 0.55,
  targetLightLuma: 0.74,
  minNormalLuma: 0.3,
  targetNormalLuma: 0.5,
  maxNormalLuma: 0.7,
  targetMutesSaturation: 0.3,
  maxMutesSaturation: 0.4,
  targetVibrantSaturation: 1,
  minVibrantSaturation: 0.35,
  weightSaturation: 3,
  weightLuma: 6.5,
  weightPopulation: 0.5
};
function _findMaxPopulation(swatches) {
  let p = 0;
  swatches.forEach((s) => {
    p = Math.max(p, s.population);
  });
  return p;
}
function _isAlreadySelected(palette, s) {
  return palette.Vibrant === s || palette.DarkVibrant === s || palette.LightVibrant === s || palette.Muted === s || palette.DarkMuted === s || palette.LightMuted === s;
}
function _createComparisonValue(saturation, targetSaturation, luma, targetLuma, population, maxPopulation, opts) {
  function weightedMean(...values) {
    let sum = 0;
    let weightSum = 0;
    for (let i = 0; i < values.length; i += 2) {
      const value = values[i];
      const weight = values[i + 1];
      if (!value || !weight) continue;
      sum += value * weight;
      weightSum += weight;
    }
    return sum / weightSum;
  }
  function invertDiff(value, targetValue) {
    return 1 - Math.abs(value - targetValue);
  }
  return weightedMean(
    invertDiff(saturation, targetSaturation),
    opts.weightSaturation,
    invertDiff(luma, targetLuma),
    opts.weightLuma,
    population / maxPopulation,
    opts.weightPopulation
  );
}
function _findColorVariation(palette, swatches, maxPopulation, targetLuma, minLuma, maxLuma, targetSaturation, minSaturation, maxSaturation, opts) {
  let max = null;
  let maxValue = 0;
  swatches.forEach((swatch) => {
    const [, s, l] = swatch.hsl;
    if (s >= minSaturation && s <= maxSaturation && l >= minLuma && l <= maxLuma && !_isAlreadySelected(palette, swatch)) {
      const value = _createComparisonValue(
        s,
        targetSaturation,
        l,
        targetLuma,
        swatch.population,
        maxPopulation,
        opts
      );
      if (max === null || value > maxValue) {
        max = swatch;
        maxValue = value;
      }
    }
  });
  return max;
}
function _generateVariationColors(swatches, maxPopulation, opts) {
  const palette = {
    Vibrant: null,
    DarkVibrant: null,
    LightVibrant: null,
    Muted: null,
    DarkMuted: null,
    LightMuted: null
  };
  palette.Vibrant = _findColorVariation(
    palette,
    swatches,
    maxPopulation,
    opts.targetNormalLuma,
    opts.minNormalLuma,
    opts.maxNormalLuma,
    opts.targetVibrantSaturation,
    opts.minVibrantSaturation,
    1,
    opts
  );
  palette.LightVibrant = _findColorVariation(
    palette,
    swatches,
    maxPopulation,
    opts.targetLightLuma,
    opts.minLightLuma,
    1,
    opts.targetVibrantSaturation,
    opts.minVibrantSaturation,
    1,
    opts
  );
  palette.DarkVibrant = _findColorVariation(
    palette,
    swatches,
    maxPopulation,
    opts.targetDarkLuma,
    0,
    opts.maxDarkLuma,
    opts.targetVibrantSaturation,
    opts.minVibrantSaturation,
    1,
    opts
  );
  palette.Muted = _findColorVariation(
    palette,
    swatches,
    maxPopulation,
    opts.targetNormalLuma,
    opts.minNormalLuma,
    opts.maxNormalLuma,
    opts.targetMutesSaturation,
    0,
    opts.maxMutesSaturation,
    opts
  );
  palette.LightMuted = _findColorVariation(
    palette,
    swatches,
    maxPopulation,
    opts.targetLightLuma,
    opts.minLightLuma,
    1,
    opts.targetMutesSaturation,
    0,
    opts.maxMutesSaturation,
    opts
  );
  palette.DarkMuted = _findColorVariation(
    palette,
    swatches,
    maxPopulation,
    opts.targetDarkLuma,
    0,
    opts.maxDarkLuma,
    opts.targetMutesSaturation,
    0,
    opts.maxMutesSaturation,
    opts
  );
  return palette;
}
function _generateEmptySwatches(palette, _maxPopulation, opts) {
  if (!palette.Vibrant && !palette.DarkVibrant && !palette.LightVibrant) {
    if (!palette.DarkVibrant && palette.DarkMuted) {
      let [h, s, l] = palette.DarkMuted.hsl;
      l = opts.targetDarkLuma;
      palette.DarkVibrant = new Swatch(hslToRgb(h, s, l), 0);
    }
    if (!palette.LightVibrant && palette.LightMuted) {
      let [h, s, l] = palette.LightMuted.hsl;
      l = opts.targetDarkLuma;
      palette.DarkVibrant = new Swatch(hslToRgb(h, s, l), 0);
    }
  }
  if (!palette.Vibrant && palette.DarkVibrant) {
    let [h, s, l] = palette.DarkVibrant.hsl;
    l = opts.targetNormalLuma;
    palette.Vibrant = new Swatch(hslToRgb(h, s, l), 0);
  } else if (!palette.Vibrant && palette.LightVibrant) {
    let [h, s, l] = palette.LightVibrant.hsl;
    l = opts.targetNormalLuma;
    palette.Vibrant = new Swatch(hslToRgb(h, s, l), 0);
  }
  if (!palette.DarkVibrant && palette.Vibrant) {
    let [h, s, l] = palette.Vibrant.hsl;
    l = opts.targetDarkLuma;
    palette.DarkVibrant = new Swatch(hslToRgb(h, s, l), 0);
  }
  if (!palette.LightVibrant && palette.Vibrant) {
    let [h, s, l] = palette.Vibrant.hsl;
    l = opts.targetLightLuma;
    palette.LightVibrant = new Swatch(hslToRgb(h, s, l), 0);
  }
  if (!palette.Muted && palette.Vibrant) {
    let [h, s, l] = palette.Vibrant.hsl;
    l = opts.targetMutesSaturation;
    palette.Muted = new Swatch(hslToRgb(h, s, l), 0);
  }
  if (!palette.DarkMuted && palette.DarkVibrant) {
    let [h, s, l] = palette.DarkVibrant.hsl;
    l = opts.targetMutesSaturation;
    palette.DarkMuted = new Swatch(hslToRgb(h, s, l), 0);
  }
  if (!palette.LightMuted && palette.LightVibrant) {
    let [h, s, l] = palette.LightVibrant.hsl;
    l = opts.targetMutesSaturation;
    palette.LightMuted = new Swatch(hslToRgb(h, s, l), 0);
  }
}
const DefaultGenerator = (swatches, opts) => {
  opts = Object.assign({}, DefaultOpts, opts);
  const maxPopulation = _findMaxPopulation(swatches);
  const palette = _generateVariationColors(swatches, maxPopulation, opts);
  _generateEmptySwatches(palette, maxPopulation, opts);
  return palette;
};
export {
  DefaultGenerator,
  DefaultOpts
};

