import { Histogram } from "./histogram.js";
class ImageBase {
  scaleDown(opts) {
    const width = this.getWidth();
    const height = this.getHeight();
    let ratio = 1;
    if (opts.maxDimension > 0) {
      const maxSide = Math.max(width, height);
      if (maxSide > opts.maxDimension) ratio = opts.maxDimension / maxSide;
    } else {
      ratio = 1 / opts.quality;
    }
    if (ratio < 1) this.resize(width * ratio, height * ratio, ratio);
  }
}
function applyFilters(imageData, filters) {
  var _a;
  if (filters.length > 0) {
    const pixels = imageData.data;
    const n = pixels.length / 4;
    let offset;
    let r;
    let g;
    let b;
    let a;
    for (let i = 0; i < n; i++) {
      offset = i * 4;
      r = pixels[offset + 0];
      g = pixels[offset + 1];
      b = pixels[offset + 2];
      a = pixels[offset + 3];
      for (let j = 0; j < filters.length; j++) {
        if (!((_a = filters[j]) == null ? void 0 : _a.call(filters, r, g, b, a))) {
          pixels[offset + 3] = 0;
          break;
        }
      }
    }
  }
  return imageData;
}
export {
  Histogram,
  ImageBase,
  applyFilters
};

