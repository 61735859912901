import { ImageBase } from "@vibrant/image";
function isRelativeUrl(url) {
  const u = new URL(url, location.href);
  return u.protocol === location.protocol && u.host === location.host && u.port === location.port;
}
function isSameOrigin(a, b) {
  const ua = new URL(a);
  const ub = new URL(b);
  return ua.protocol === ub.protocol && ua.hostname === ub.hostname && ua.port === ub.port;
}
class BrowserImage extends ImageBase {
  _getCanvas() {
    if (!this._canvas) {
      throw new Error("Canvas is not initialized");
    }
    return this._canvas;
  }
  _getContext() {
    if (!this._context) {
      throw new Error("Context is not initialized");
    }
    return this._context;
  }
  _getWidth() {
    if (!this._width) {
      throw new Error("Width is not initialized");
    }
    return this._width;
  }
  _getHeight() {
    if (!this._height) {
      throw new Error("Height is not initialized");
    }
    return this._height;
  }
  _initCanvas() {
    const img = this.image;
    if (!img) {
      throw new Error("Image is not initialized");
    }
    const canvas = this._canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (!context) throw new ReferenceError("Failed to create canvas context");
    this._context = context;
    canvas.className = "@vibrant/canvas";
    canvas.style.display = "none";
    this._width = canvas.width = img.width;
    this._height = canvas.height = img.height;
    context.drawImage(img, 0, 0);
    document.body.appendChild(canvas);
  }
  load(image) {
    let img;
    let src;
    if (typeof image === "string") {
      img = document.createElement("img");
      src = image;
      if (!isRelativeUrl(src) && !isSameOrigin(window.location.href, src)) {
        img.crossOrigin = "anonymous";
      }
      img.src = src;
    } else if (image instanceof HTMLImageElement) {
      img = image;
      src = image.src;
    } else {
      return Promise.reject(
        new Error(`Cannot load buffer as an image in browser`)
      );
    }
    this.image = img;
    return new Promise((resolve, reject) => {
      const onImageLoad = () => {
        this._initCanvas();
        resolve(this);
      };
      if (img.complete) {
        onImageLoad();
      } else {
        img.onload = onImageLoad;
        img.onerror = (_e) => reject(new Error(`Fail to load image: ${src}`));
      }
    });
  }
  clear() {
    this._getContext().clearRect(0, 0, this._getWidth(), this._getHeight());
  }
  update(imageData) {
    this._getContext().putImageData(imageData, 0, 0);
  }
  getWidth() {
    return this._getWidth();
  }
  getHeight() {
    return this._getHeight();
  }
  resize(targetWidth, targetHeight, ratio) {
    if (!this.image) {
      throw new Error("Image is not initialized");
    }
    this._width = this._getCanvas().width = targetWidth;
    this._height = this._getCanvas().height = targetHeight;
    this._getContext().scale(ratio, ratio);
    this._getContext().drawImage(this.image, 0, 0);
  }
  getPixelCount() {
    return this._getWidth() * this._getHeight();
  }
  getImageData() {
    return this._getContext().getImageData(
      0,
      0,
      this._getWidth(),
      this._getHeight()
    );
  }
  remove() {
    if (this._canvas && this._canvas.parentNode) {
      this._canvas.parentNode.removeChild(this._canvas);
    }
  }
}
export {
  BrowserImage
};

