import { rgbToHsl, rgbToHex } from "./converter.js";
import { DELTAE94_DIFF_STATUS, deltaE94, getColorDiffStatus, hexDiff, hexToRgb, hslToRgb, rgbDiff, rgbToCIELab, rgbToXyz, xyzToCIELab } from "./converter.js";
class Swatch {
  static applyFilters(colors, filters) {
    return filters.length > 0 ? colors.filter(({ r, g, b }) => {
      var _a;
      for (let j = 0; j < filters.length; j++) {
        if (!((_a = filters[j]) == null ? void 0 : _a.call(filters, r, g, b, 255))) return false;
      }
      return true;
    }) : colors;
  }
  /**
   * Make a value copy of a swatch based on a previous one. Returns a new Swatch instance
   * @param {Swatch} swatch
   */
  static clone(swatch) {
    return new Swatch(swatch._rgb, swatch._population);
  }
  /**
   * The red value in the RGB value
   */
  get r() {
    return this._rgb[0];
  }
  /**
   * The green value in the RGB value
   */
  get g() {
    return this._rgb[1];
  }
  /**
   * The blue value in the RGB value
   */
  get b() {
    return this._rgb[2];
  }
  /**
   * The color value as a rgb value
   */
  get rgb() {
    return this._rgb;
  }
  /**
   * The color value as a hsl value
   */
  get hsl() {
    if (!this._hsl) {
      const [r, g, b] = this._rgb;
      this._hsl = rgbToHsl(r, g, b);
    }
    return this._hsl;
  }
  /**
   * The color value as a hex string
   */
  get hex() {
    if (!this._hex) {
      const [r, g, b] = this._rgb;
      this._hex = rgbToHex(r, g, b);
    }
    return this._hex;
  }
  get population() {
    return this._population;
  }
  /**
   * Get the JSON object for the swatch
   */
  toJSON() {
    return {
      rgb: this.rgb,
      population: this.population
    };
  }
  getYiq() {
    if (!this._yiq) {
      const rgb = this._rgb;
      this._yiq = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1e3;
    }
    return this._yiq;
  }
  /**
   * Returns an appropriate color to use for any 'title' text which is displayed over this Swatch's color.
   */
  get titleTextColor() {
    if (!this._titleTextColor) {
      this._titleTextColor = this.getYiq() < 200 ? "#fff" : "#000";
    }
    return this._titleTextColor;
  }
  /**
   * Returns an appropriate color to use for any 'body' text which is displayed over this Swatch's color.
   */
  get bodyTextColor() {
    if (!this._bodyTextColor) {
      this._bodyTextColor = this.getYiq() < 150 ? "#fff" : "#000";
    }
    return this._bodyTextColor;
  }
  /**
   * Internal use.
   * @param rgb `[r, g, b]`
   * @param population Population of the color in an image
   */
  constructor(rgb, population) {
    this._rgb = rgb;
    this._population = population;
  }
}
export {
  DELTAE94_DIFF_STATUS,
  Swatch,
  deltaE94,
  getColorDiffStatus,
  hexDiff,
  hexToRgb,
  hslToRgb,
  rgbDiff,
  rgbToCIELab,
  rgbToHex,
  rgbToHsl,
  rgbToXyz,
  xyzToCIELab
};

