class Histogram {
  constructor(pixels, opts) {
    this.pixels = pixels;
    this.opts = opts;
    const { sigBits } = opts;
    const getColorIndex = (r2, g2, b2) => (r2 << 2 * sigBits) + (g2 << sigBits) + b2;
    this.getColorIndex = getColorIndex;
    const rshift = 8 - sigBits;
    const hn = 1 << 3 * sigBits;
    const hist = new Uint32Array(hn);
    let rmax;
    let rmin;
    let gmax;
    let gmin;
    let bmax;
    let bmin;
    let r;
    let g;
    let b;
    let a;
    rmax = gmax = bmax = 0;
    rmin = gmin = bmin = Number.MAX_VALUE;
    const n = pixels.length / 4;
    let i = 0;
    while (i < n) {
      const offset = i * 4;
      i++;
      r = pixels[offset + 0];
      g = pixels[offset + 1];
      b = pixels[offset + 2];
      a = pixels[offset + 3];
      if (a === 0) continue;
      r = r >> rshift;
      g = g >> rshift;
      b = b >> rshift;
      const index = getColorIndex(r, g, b);
      if (hist[index] === void 0) hist[index] = 0;
      hist[index] += 1;
      if (r > rmax) rmax = r;
      if (r < rmin) rmin = r;
      if (g > gmax) gmax = g;
      if (g < gmin) gmin = g;
      if (b > bmax) bmax = b;
      if (b < bmin) bmin = b;
    }
    this._colorCount = hist.reduce(
      (total, c) => c > 0 ? total + 1 : total,
      0
    );
    this.hist = hist;
    this.rmax = rmax;
    this.rmin = rmin;
    this.gmax = gmax;
    this.gmin = gmin;
    this.bmax = bmax;
    this.bmin = bmin;
  }
  get colorCount() {
    return this._colorCount;
  }
}
export {
  Histogram
};

