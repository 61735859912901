import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private snack: MatSnackBar) {}

  showNotification(message: string, duration = 3000): void {
    this.snack.open(message, 'OK', { duration });
  }
}
