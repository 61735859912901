import { assignDeep } from "./utils.js";
import { Vibrant } from "./index.js";
class Builder {
  /**
   * Arguments are the same as `Vibrant.constructor`.
   */
  constructor(src, opts = {}) {
    this._src = src;
    this._opts = assignDeep({}, Vibrant.DefaultOpts, opts);
  }
  /**
   * Sets `opts.colorCount` to `n`.
   * @returns this `Builder` instance.
   */
  maxColorCount(n) {
    this._opts.colorCount = n;
    return this;
  }
  /**
   * Sets `opts.maxDimension` to `d`.
   * @returns this `Builder` instance.
   */
  maxDimension(d) {
    this._opts.maxDimension = d;
    return this;
  }
  /**
   * Adds a filter function
   * @returns this `Builder` instance.
   */
  addFilter(name) {
    if (!this._opts.filters) {
      this._opts.filters = [name];
    } else {
      this._opts.filters.push(name);
    }
    return this;
  }
  /**
   * Removes a filter function.
   * @returns this `Builder` instance.
   */
  removeFilter(name) {
    if (this._opts.filters) {
      const i = this._opts.filters.indexOf(name);
      if (i > 0) this._opts.filters.splice(i);
    }
    return this;
  }
  /**
   * Clear all filters.
   * @returns this `Builder` instance.
   */
  clearFilters() {
    this._opts.filters = [];
    return this;
  }
  /**
   * Sets `opts.quality` to `q`.
   * @returns this `Builder` instance.
   */
  quality(q) {
    this._opts.quality = q;
    return this;
  }
  /**
   * Specifies which `Image` implementation class to use.
   * @returns this `Builder` instance.
   */
  useImageClass(imageClass) {
    this._opts.ImageClass = imageClass;
    return this;
  }
  /**
   * Sets `opts.generator` to `generator`
   * @returns this `Builder` instance.
   */
  useGenerator(generator, options) {
    if (!this._opts.generators) this._opts.generators = [];
    this._opts.generators.push(
      options ? { name: generator, options } : generator
    );
    return this;
  }
  /**
   * Specifies which `Quantizer` implementation class to use
   * @returns this `Builder` instance.
   */
  useQuantizer(quantizer, options) {
    this._opts.quantizer = options ? { name: quantizer, options } : quantizer;
    return this;
  }
  /**
   * Builds and returns a `Vibrant` instance as configured.
   */
  build() {
    return new Vibrant(this._src, this._opts);
  }
  /**
   * Builds a `Vibrant` instance as configured and calls its `getPalette` method.
   */
  getPalette() {
    return this.build().getPalette();
  }
}
export {
  Builder
};

