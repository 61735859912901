export var AssetType;
(function (AssetType) {
    AssetType["GAME"] = "GAME";
    AssetType["REPORT"] = "REPORT";
    AssetType["FONT"] = "FONT";
    AssetType["CLIENT"] = "CLIENT";
    AssetType["AUDIO"] = "AUDIO";
})(AssetType || (AssetType = {}));
export var SportType;
(function (SportType) {
    SportType["SOCCER"] = "SOCCER";
})(SportType || (SportType = {}));
