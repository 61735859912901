import { Swatch } from "@vibrant/color";
import { VBox } from "./vbox.js";
import { PQueue } from "./pqueue.js";
const fractByPopulations = 0.75;
function _splitBoxes(pq, target) {
  let lastSize = pq.size();
  while (pq.size() < target) {
    const vbox = pq.pop();
    if (vbox && vbox.count() > 0) {
      const [vbox1, vbox2] = vbox.split();
      if (!vbox1) break;
      pq.push(vbox1);
      if (vbox2 && vbox2.count() > 0) pq.push(vbox2);
      if (pq.size() === lastSize) {
        break;
      } else {
        lastSize = pq.size();
      }
    } else {
      break;
    }
  }
}
const MMCQ = (pixels, opts) => {
  if (pixels.length === 0 || opts.colorCount < 2 || opts.colorCount > 256) {
    throw new Error("Wrong MMCQ parameters");
  }
  const vbox = VBox.build(pixels);
  vbox.histogram.colorCount;
  const pq = new PQueue((a, b) => a.count() - b.count());
  pq.push(vbox);
  _splitBoxes(pq, fractByPopulations * opts.colorCount);
  const pq2 = new PQueue(
    (a, b) => a.count() * a.volume() - b.count() * b.volume()
  );
  pq2.contents = pq.contents;
  _splitBoxes(pq2, opts.colorCount - pq2.size());
  return generateSwatches(pq2);
};
function generateSwatches(pq) {
  const swatches = [];
  while (pq.size()) {
    const v = pq.pop();
    const color = v.avg();
    swatches.push(new Swatch(color, v.count()));
  }
  return swatches;
}
export {
  MMCQ
};

