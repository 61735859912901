import { HttpClient } from '@angular/common/http';
import { Injectable, inject, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { UserActivityService } from '@services/user-activity.service';
import { injectCSSVariablesIntoDOM } from '@shared/utils/theme.util';
import {
  BehaviorSubject,
  interval,
  filter,
  switchMap,
  Observable,
  mergeMap,
  catchError,
  map,
} from 'rxjs';
import { Theme, ConfigValue } from '@shared/types/theme.type';
import { UserInfo } from '@shared/types/user.type';
import { UserAction } from '@longnecktech/splash-commons-fe';
import { UserActionService } from './user-action.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private _user = new BehaviorSubject<UserInfo | undefined>(undefined);
  private _isMobile = new BehaviorSubject<boolean>(false);
  private _theme = new BehaviorSubject<Theme | null>(null);

  thirdPartyToken = '';
  jackpotGroupId?: string;
  gameTag?: string;
  instance?: string;
  jackpotWinTransactionId?: string;
  readonly user$ = this._user.asObservable();
  readonly isMobile$ = this._isMobile.asObservable();
  readonly theme$ = this._theme.asObservable();

  private destroyRef = inject(DestroyRef);

  constructor(
    private http: HttpClient,
    private userActivityService: UserActivityService,
    private router: Router,
    private userActionService: UserActionService,
  ) {}

  setUser(userInfo: UserInfo): void {
    this._user.next(userInfo);
  }

  setTheme(theme: Theme | null): void {
    this._theme.next(theme);
    if (theme) {
      injectCSSVariablesIntoDOM(theme);
    }
  }

  getLabels(): ConfigValue[] {
    return this._theme.value?.labels || [];
  }

  updateAuthToken(): void {
    // Run every 10 minutes
    interval(10 * 60 * 1000)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        // this interval must not refresh the token trigger if the user is not active
        filter(() => !this.userActivityService.isUserInactive()),
        switchMap(() => this.fetchToken()),
      )
      .subscribe({
        next: (user) => {
          this.setUser(user);
        },
        error: () => {
          this.router.navigate(['/error']);
        },
      });
  }

  updateIsMobile(isMobile: boolean): void {
    this._isMobile.next(isMobile);
  }

  fetchToken(): Observable<UserInfo> {
    return this.http
      .post<UserInfo>(`${environment.backendUrl}/api/public/auth/token`, {
        token: this.thirdPartyToken,
        instance: this.instance,
      })
      .pipe(
        mergeMap<UserInfo, Observable<UserInfo>>((user) =>
          this.userActionService
            .sendAction(UserAction.GAME_LOGIN, {}, '')
            .pipe(map(() => user)),
        ),
        catchError((err) => {
          console.error('Error fetching token:', err);
          throw err;
        }),
      );
  }
}
