import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SessionService } from '@services/session.service';
import {
  JackpotLevel,
  JackpotSegment,
  WonJackpotLevel,
  JackpotLevelWithColor,
} from '@shared/types/jackpot-level';
import { JackpotWinTransaction } from '@shared/types/jackpot-transaction';
import {
  JackpotGroup,
  JackpotGroupResponse,
  JackpotParticipation,
} from '@shared/types/jackpot-group';
import { Vibrant } from 'node-vibrant/browser';
import {
  Observable,
  tap,
  catchError,
  throwError,
  BehaviorSubject,
  EMPTY,
  map,
  filter,
  switchMap,
  forkJoin,
  withLatestFrom,
  shareReplay,
} from 'rxjs';

import { environment } from '@environment';
import {
  ContributeRequest,
  ParticipationRequest,
} from '@shared/types/jackpot.type';
import { NotificationService } from '@services/notification.service';

@Injectable({
  providedIn: 'root',
})
export class JackpotService {
  private _jackpotGroup = new BehaviorSubject<JackpotGroup | null>(null);
  private _jackpotWinTransaction = new BehaviorSubject<
    JackpotWinTransaction | undefined
  >(undefined);
  private _levels = new BehaviorSubject<JackpotLevel[]>([]);
  private _participation = new BehaviorSubject<boolean>(false);
  readonly jackpotGroup$ = this._jackpotGroup.asObservable();
  readonly levels$ = this._levels.asObservable();
  readonly levelsWithColor$ = this.levels$.pipe(
    switchMap((levels) => {
      // Create an array of observables, one for each image color extraction
      const colorExtractions$ = levels.map((level) =>
        this.extractImageColors(level),
      );

      // Execute all color extractions in parallel and combine results
      return forkJoin(colorExtractions$);
    }),
    // Share the result with all subscribers and cache the latest emission
    shareReplay(1),
  );
  readonly jackpotWinTransaction$ = this._jackpotWinTransaction.asObservable();
  readonly wonJackpotLevel$ = this.levelsWithColor$.pipe(
    filter((levels) => !!levels?.length),
    withLatestFrom(this.jackpotWinTransaction$),
    map(([levels, jackpot]) => {
      const wonJackpotLevel = levels.find(
        (level) => level.name === jackpot!.wonGameName,
      );
      return {
        ...wonJackpotLevel,
        winAmount: jackpot!.winAmount,
      } as WonJackpotLevel;
    }),
  );
  readonly wheelSegments$ = this.levelsWithColor$.pipe(
    withLatestFrom(this.jackpotWinTransaction$),
    map(
      ([levels, res]: [
        JackpotLevelWithColor[],
        JackpotWinTransaction | undefined,
      ]) => this.getWheelSegments(levels, res?.wonGameName),
    ),
  );
  readonly participation$ = this._participation.asObservable();

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private session: SessionService,
  ) {}

  saveContribution(request: ContributeRequest): Observable<void> {
    return this.http
      .post<void>(`${environment.backendUrl}/api/jackpot/contribute`, request)
      .pipe(
        tap(() => {
          this.notificationService.showNotification('Contribution saved');
        }),
        catchError((err) => {
          this.notificationService.showNotification(
            err?.message || 'Error saving contribution',
          );
          return throwError(() => new Error(err.message));
        }),
      );
  }

  fetchParticipationState(groupUuid: string): Observable<JackpotParticipation> {
    return this.http
      .get<JackpotParticipation>(
        `${environment.backendUrl}/api/jackpot/participation/group/${groupUuid}`,
      )
      .pipe(
        tap((res) => {
          this._participation.next(!!res?.participate);
        }),
        catchError(() => {
          return EMPTY;
        }),
      );
  }

  fetchParticipationStateByTag(tag: string): Observable<JackpotParticipation> {
    return this.http
      .get<JackpotParticipation>(
        `${environment.backendUrl}/api/jackpot/participation/tag/${tag}`,
      )
      .pipe(
        tap((res) => {
          this._participation.next(!!res?.participate);
        }),
      );
  }

  saveParticipation(
    request: ParticipationRequest,
  ): Observable<JackpotParticipation> {
    return this.http
      .post<JackpotParticipation>(
        `${environment.backendUrl}/api/jackpot/participation`,
        request,
      )
      .pipe(
        tap((res) => {
          this._participation.next(res.participate);
        }),
      );
  }

  fetchJackpotWinTransaction(): Observable<JackpotWinTransaction> {
    return this.http
      .get<JackpotWinTransaction>(
        `${environment.backendUrl}/api/jackpot/transaction/win`,
      )
      .pipe(
        tap((res) => {
          this._jackpotWinTransaction.next(res);
          if (res?.jackpotLevels) {
            this._levels.next(this.getUpdatedLevels(res));
          }
          if (res?.theme) {
            this.session.setTheme(res.theme);
          }
        }),
      );
  }

  fetchJackpotGroup(): Observable<JackpotGroupResponse> {
    const params = this.session.jackpotGroupId
      ? `?jackpotGroupId=${this.session.jackpotGroupId}`
      : `?gameTag=${this.session.gameTag}`;
    return this.http
      .get<JackpotGroupResponse>(
        `${environment.backendUrl}/api/jackpot/group${params}`,
      )
      .pipe(
        tap((res) => {
          this._jackpotGroup.next(res?.jackpotGroup);
          this._levels.next(res?.jackpotLevels || []);
          this.session.setTheme(res?.theme);
        }),
      );
  }

  private getUpdatedLevels(res: JackpotWinTransaction): JackpotLevel[] {
    return (res?.jackpotLevels || [])
      .map((level) => {
        if (level.name === res.wonGameName) {
          return {
            ...level,
            // NOTE: we need to replace the value of the level with the win amount
            // because it's possible that the jackpot level is not the same as the won amount
            // we decided to fix this issue on the FE
            values: {
              ...level.values,
              [level.currency]: res.winAmount,
            },
          };
        }
        return level;
      })
      .sort((a, b) => a.values[a.currency] - b.values[b.currency]);
  }

  private getWheelSegments(
    jackpotLevels: JackpotLevelWithColor[],
    wonGameName: string | undefined,
  ): JackpotSegment[] {
    const result: JackpotSegment[] = [];

    for (let i = 0; i < 4; i++) {
      const startIndex = i * jackpotLevels.length;
      const duplicatedWithIndex = jackpotLevels.map(
        (level: JackpotLevelWithColor, idx: number) => {
          return {
            filter: level.filter,
            icon: level.iconAsset?.url || 'assets/icons/default-icon.png',
            backgroundColor:
              idx % 2 === 0
                ? this.getColor('--color-base-1')
                : this.getColor('--color-accent-1'),
            label: level.name,
            index: startIndex + idx,
            // set won status only for the first loop
            isWon: wonGameName === level.name && startIndex === 0,
          };
        },
      );

      result.push(...duplicatedWithIndex);
    }

    return result;
  }

  private getColor(variable: string): string {
    return (
      getComputedStyle(document.documentElement)
        .getPropertyValue(variable)
        .trim() || '#001769'
    );
  }

  private extractImageColors(
    level: JackpotLevel,
  ): Observable<JackpotLevelWithColor> {
    const imageUrl = level.iconAsset?.url;

    return new Observable<JackpotLevelWithColor>((observer) => {
      if (!imageUrl) {
        observer.next({
          ...level,
          filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.8)',
          background: `linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%)`,
        });
        observer.complete();
      }
      Vibrant.from(imageUrl!)
        .getPalette()
        .then((palette) => {
          const color =
            palette.Vibrant?.rgb.join(', ') ||
            palette.Muted?.rgb.join(', ') ||
            '255, 255, 255';

          observer.next({
            ...level,
            filter: `drop-shadow(0px 0px 10px rgba(${color}, 0.8)`,
            background: `linear-gradient(90deg, rgba(${color}, 0.5) 0%, rgba(${color}, 0) 100%)`,
          });
          observer.complete();
        })
        .catch((error) => {
          console.log(`Failed to extract colors for image:`, error);
          // Return the original item with a fallback color
          observer.next({
            ...level,
            filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.8)',
            background: `linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%)`,
          });
          observer.complete();
        });
    });
  }
}
