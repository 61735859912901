import { ConfigValue, Theme } from '@shared/types/theme.type';

export function injectCSSVariablesIntoDOM(theme: Theme) {
  const styleEl = document.createElement('style');
  const fonts = `
      ${
        theme.primaryFontRegularAsset?.url
          ? `@font-face {
              font-family: PrimaryFont;
              src: url('${theme.primaryFontRegularAsset.url}') format('truetype');
            }`
          : ''
      }
    ${
      theme.primaryFontBoldAsset?.url
        ? ` @font-face {
            font-family: PrimaryFont;
            src: url('${theme.primaryFontBoldAsset.url}') format('truetype');
            font-weight: 700;
          }`
        : ''
    }
    ${
      theme.primaryFontMediumAsset?.url
        ? `@font-face {
            font-family: PrimaryFont;
            src: url('${theme.primaryFontMediumAsset.url}') format('truetype');
            font-weight: 500;
          }`
        : ''
    }
    ${
      theme.jackpotFontAsset?.url
        ? ` @font-face {
            font-family: Headlines;
            src: url('${theme.jackpotFontAsset.url}') format('truetype');
          }`
        : ''
    }
    `;
  styleEl.textContent = `:root {
      --primary-font: ${theme.primaryFontRegularAsset?.url ? 'PrimaryFont' : 'Roboto'}, sans-serif;
      --headline-font: ${theme.jackpotFontAsset?.url ? 'Headlines' : 'Inter'}, sans-serif;
      ${theme.cssVariables
        .filter((v: ConfigValue) => v.key.includes('--') && v.value)
        .map((v: ConfigValue) => {
          const originalValue = v.value;
          let rgbValue;

          if (originalValue.startsWith('rgba')) {
            const match = originalValue.match(/\d+/g);
            if (match) {
              rgbValue = `${match[0]}, ${match[1]}, ${match[2]}`;
            }
          } else if (originalValue.startsWith('rgb')) {
            const match = originalValue.match(/\d+/g);
            if (match) {
              rgbValue = match.join(', ');
            }
          } else if (originalValue.startsWith('#')) {
            rgbValue = hexToRgb(originalValue);
          }

          return `
          ${v.key}: ${originalValue};
          ${v.key}-rgb: ${rgbValue};
        `;
        })
        .join('\n')}
        --corner-radius-cta: ${theme.cornerRadiusCta !== null && theme.cornerRadiusCta !== undefined ? theme.cornerRadiusCta : 28}px;
    }
    ${fonts}
    `;
  document.head.appendChild(styleEl);
}

function hexToRgb(hex: string): string {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r}, ${g}, ${b}`;
}
