export var IframeMessage;
(function (IframeMessage) {
    IframeMessage["Close"] = "close";
    IframeMessage["Upsell1"] = "upsell-1";
    IframeMessage["TermsConditions"] = "terms-conditions";
    IframeMessage["GameRules"] = "game-rules";
    IframeMessage["Faq"] = "faq";
    IframeMessage["Deposit"] = "deposit";
    IframeMessage["Deeplinking"] = "deeplinking";
    IframeMessage["Login"] = "login";
    IframeMessage["Signup"] = "signup";
    IframeMessage["UpdateHeight"] = "update-height";
    IframeMessage["JackpotWinClose"] = "jackpot-win-close";
})(IframeMessage || (IframeMessage = {}));
