export var CampaignType;
(function (CampaignType) {
    CampaignType["Image"] = "IMAGE";
    CampaignType["Html"] = "HTML";
})(CampaignType || (CampaignType = {}));
export var CampaignTriggerType;
(function (CampaignTriggerType) {
    CampaignTriggerType["Image"] = "IMAGE";
    CampaignTriggerType["Button"] = "BUTTON";
    CampaignTriggerType["None"] = "NONE";
})(CampaignTriggerType || (CampaignTriggerType = {}));
export var CampaignPlacement;
(function (CampaignPlacement) {
    CampaignPlacement["PostSubmission"] = "POST_SUBMISSION";
    CampaignPlacement["Result"] = "RESULTS";
    CampaignPlacement["Lobby"] = "LOBBY";
})(CampaignPlacement || (CampaignPlacement = {}));
