/**
 * * liveState
 *     0 = NotStarted - Indicates that the associated sport event has not yet been started
 *     1 = Live - Indicates that the associated sport event is currently live
 *     2 = Suspended - Indicates that the associated sport event has already been started, but is currently suspended
 *     3 = Ended - Indicates that the associated sport event has already ended
 *     4 = Closed - Indicates that the associated sport event has already ended and no more changes for this event will be transmitted by the system
 *     5 = Cancelled - The sport event has been cancelled, the event will not take place, there will be no results
 *     6 = Abandoned - Sportradar aborts scouting the match - this means there will be no live reporting; the match will likely take place anyhow, and after the match has been played Sportradar will likely enter the results and the match will be moved to closed/finished
 *     7 = Delayed - If a match has passed its scheduled start time but is delayed, unknown when it will start this is something that often happens in Tennis
 *     8 = Unknown - If a hitherto unsupported sport-event-status is received
 *     9 = Postponed - Indicates that the associated sport event is postponed
 *     10 = Interrupted - Indicates that the associated sport event is interrupted. Interruption is expected to be just a few minutes. Longer interruptions may lead to a match being suspended, or possibly postponed.
 *     11 = AboutToStart - The match is about to start
 */
export var LiveState;
(function (LiveState) {
    LiveState[LiveState["NotStarted"] = 0] = "NotStarted";
    LiveState[LiveState["Live"] = 1] = "Live";
    LiveState[LiveState["Suspended"] = 2] = "Suspended";
    LiveState[LiveState["Ended"] = 3] = "Ended";
    LiveState[LiveState["Closed"] = 4] = "Closed";
    LiveState[LiveState["Cancelled"] = 5] = "Cancelled";
    LiveState[LiveState["Abandoned"] = 6] = "Abandoned";
    LiveState[LiveState["Delayed"] = 7] = "Delayed";
    LiveState[LiveState["Unknown"] = 8] = "Unknown";
    LiveState[LiveState["Postponed"] = 9] = "Postponed";
    LiveState[LiveState["Interrupted"] = 10] = "Interrupted";
    LiveState[LiveState["AboutToStart"] = 11] = "AboutToStart";
})(LiveState || (LiveState = {}));
