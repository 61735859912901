import "./configs/browser.js";
import { pipeline } from "./pipeline/index.js";
import "./configs/config.js";
import { Vibrant } from "@vibrant/core";
import { Vibrant as Vibrant2 } from "@vibrant/core";
Vibrant.use(pipeline);
export {
  Vibrant2 as Vibrant
};

